import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { slugify, artistname } from '../../lib/utils'
import Image from 'gatsby-plugin-sanity-image'
import Apple from '../../images/apple.svg'
import Spotify from '../../images/spotify.svg'
import Acast from '../../images/acast.svg'
import BBC from '../../images/bbc.svg'
import Amazon from '../../images/amazon.svg'
import Pocketcasts from '../../images/pocketcasts.svg'
import Google from '../../images/google.svg'
import DefaultImage from '../../images/news_default.jpg'


const Container = styled.div`
  max-width: calc(100vw - 2rem);
  img {
    max-width: 100%;
  }
`

const Links = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 16px;
  margin-top: 12px;
  img {
    height: 32px;
    filter: grayscale(1) contrast(100) brightness(1);
    mix-blend-mode: multiply;
    &:hover {
      filter: none;
      mix-blend-mode: normal;
    }
  }
`

const HostName = styled.h3`
  text-align: center;
  margin-top: 16px;
  font-size: 28px;
  line-height: 34px;
`

const ImageContainerDefault = styled.div`
  aspect-ratio: 1 / 1;
  img {
    border: 1px solid transparent;
    transition: border 0.25s ease-in-out;
    object-fit: cover;
    aspect-ratio: 1 / 1;
    &:hover {
      border-color: var(--pink);
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
   }
  }
`

const ImageContainer = styled.div`
  img {
    border: 1px solid transparent;
    transition: border 0.25s ease-in-out;
    &:hover {
      border-color: var(--pink);
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
   }
  }
`

const PodcastName = styled.p`
  text-align: center;
  font-weight: bold;
`

const Podcast = ({ artist }) => {

  const artist_name = artistname(artist);
  const artist_link = '/artists/' + slugify(artist_name) + '#podcasts';

  return (

    artist.artist_podcasts.map((p, index) => {

      const related_artists = p._rawRelatedArtists;
      const extra_artists = related_artists && related_artists.length > 0 ? related_artists.map(r => artistname(r)) : [];
      const extra_artists_joined = extra_artists.length > 1 ? extra_artists.join(' & ') : extra_artists[0];

      let host = extra_artists_joined ? artist_name + ' & ' + extra_artists_joined : artist_name;

      return (
        <Container key={index}>
          <Link to={artist_link}>
            {!p.image &&
              <ImageContainerDefault>
                <img src={DefaultImage} alt={host} />
              </ImageContainerDefault>
            }
            {p.image &&
              <ImageContainer>
                <Image
                  {...p.image}
                  alt={artist_name}
                  style={{
                    marginBottom: "36px",
                    width: "100%",
                    height: "100%",
                    aspectRatio: 1 / 1,
                    objectFit: "cover",
                  }}
                />
              </ImageContainer>
            }
          </Link>
          <HostName>{host}</HostName>
          <PodcastName>{p.title}</PodcastName>
          <Links>
            {p.podcast_acast &&
              <a href={p.podcast_acast}><img src={Acast} alt="Acast" /></a>
            }

            {p.podcast_apple &&
              <a href={p.podcast_apple}><img src={Apple} alt="Apple" /></a>
            }

            {p.podcast_spotify &&
              <a href={p.podcast_spotify}><img src={Spotify} alt="Spotify" /></a>
            }

            {p.podcast_bbc &&
              <a href={p.podcast_bbc}><img className="logo" src={BBC} alt="BBC" /></a>
            }

            {p.podcast_amazon &&
              <a href={p.podcast_amazon}><img className="logo" src={Amazon} alt="Amazon" /></a>
            }

            {p.podcast_pocketcasts &&
              <a href={p.podcast_pocketcasts}><img className="logo" src={Pocketcasts} alt="Pocketcasts" /></a>
            }

            {p.podcast_google &&
              <a href={p.podcast_google}><img className="logo" src={Google} alt="Google" /></a>
            }

          </Links>
        </Container>
      )
    })
  )
}

export default Podcast
