import React from 'react'
import Title from '../components/title'
import Podcasts from '../components/podcasts'
import PageSEO from '../components/page_seo'

const PagePodcasts = () => {
  return (
    <>
      <PageSEO path="/podcasts" />
      <Title>Podcasts</Title>
      <Podcasts />
    </>
  )
}

export default PagePodcasts;
