import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Grid from '../grid'
import Podcast from '../podcast'

const Container = styled.div`
  padding-top: 2rem;
`

const Podcasts = () => {

  const data = useStaticQuery(graphql`
    query {
      allSanityArtist(sort: {fields: first_name}) {
        edges {
          node {
            first_name
            surname
            artist_podcasts {
              image {
                ...ImageWithPreview
              }
              _rawRelatedArtists(resolveReferences: {maxDepth: 10})
              podcast_acast
              podcast_amazon
              podcast_apple
              podcast_bbc
              podcast_google
              podcast_pocketcasts
              podcast_spotify
              title
            }
          }
        }
      }
    }
  `)

  const podcasts = data.allSanityArtist.edges.filter(p => p.node.artist_podcasts.length > 0);

  return (
    <Container>
      <Grid maxwidth="1240px" colgap="56px" rowgap="53px">
        {podcasts.map((p, index) => {
          return (
            <Podcast key={index} artist={p.node} />
          )
        })}
      </Grid>
    </Container>
  )
}

export default Podcasts
